/**
 * Accordion
 * @description : List of expandable sections
 */

window.setUpAccordionItem = function (accordionItemEl) {

    var toggleEl = accordionItemEl.querySelector('.js-accordion__toggle');

    toggleEl.addEventListener('click', function () {
        accordionItemEl.classList.toggle('is-open');
    });

};

window.setUpAccordion = function (accordionEl) {

    var itemEls = accordionEl.querySelectorAll('.js-accordion__item');

    itemEls.forEach(function (itemEl) {
        window.setUpAccordionItem(itemEl);
    });

};

(function () {

    var accordionEls = document.querySelectorAll('.js-accordion');
    accordionEls.forEach(function (accordionEl) {
        window.setUpAccordion(accordionEl);
    });
     
})();
